.section-bg {
  background-color: #242424;
  color: #fff;
  padding: 4rem 0 2rem 0;
  position: relative;
}

.link {
  text-decoration: none;
  color: #fff;
}

.footer-text-container {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  background-color: #313131;
  padding: 2px !important;
}

.footer-text {

}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-section-title {
  margin-bottom: 24px;
  font-size: 20px;
  text-align: center;
}

.footer-section-title a {
  color: #fff;
  text-decoration: none;
}

.footer-subscription-text small{
  font-size: 16px;
}